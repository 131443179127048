import React from "react";
import { BymTextInput, required } from "components/Common/Forms";
import { Row, Col } from "react-bootstrap";
import BymFormValueWrapper from "components/Common/Forms/BymFormValueWrapper";
import { LeieObjekt } from "interfaces";
import { AvtaleData, UIState } from "./KontraktAvtaleUtils";
import TimeleieObjekter from "./TimeleieObjekter";
import BymCurrencyInput from "components/Common/Forms/BymCurrencyInput";

const TimeLeiePrisInfo = ({
  uiState,
  leieObjekter,
  leiesumType,
  kontraktsMal
}: {
  uiState: UIState;
  leieObjekter: LeieObjekt[];
  leiesumType?: string;
  kontraktsMal?: string;
}) => {
  return (
    <>
      <Row>
        <Col md={3}>
          <BymCurrencyInput controlId="avtaleData.timepris" label="*Timepris" rules={[required()]} />
        </Col>
        {leiesumType === "2" &&
          kontraktsMal === "4" && ( // leie av idrettsanlegg totalpris
            <Col md={3}>
              <BymCurrencyInput controlId="avtaleData.fakturaSum" label="Leiesum pr faktura" />
            </Col>
          )}
        {kontraktsMal === "6" && ( // timesleie av bad
          <Col md={3}>
            <BymCurrencyInput controlId="avtaleData.betjeningskostnadTimepris" label="Timepris betjening" />
          </Col>
        )}
      </Row>
      <Row>
        <Col md={12}>
          <br />
          <BymFormValueWrapper<AvtaleData>
            mapFunction={formValues => ({
              kontraktLeieobjekter: formValues?.kontrakt?.leieObjekter,
              useTotalPris: formValues?.avtaleData?.leiesumType === "2"
            })}
            controlId="kontrakt.leieObjekter"
            mappedPropName="kontraktLeieobjekter"
          >
            <TimeleieObjekter
              allLeieobjekter={leieObjekter}
              showPrisInfo
              timeleieType={uiState.showTimeleiePris}
              showDepositum={uiState.showDepositum === "sumOnly"}
            />
          </BymFormValueWrapper>
        </Col>
      </Row>
      {uiState.showTimeleiePris === "timeleieBetjening" && ( // bad
        <Row>
          <Col md={10}>
            <BymTextInput
              componentClass="textarea"
              controlId="avtaleData.ikkeInkludertILeien"
              placeholder="Begynn å skrive her ..."
              label="Ikke inkludert i leien"
              rows={3}
            />
          </Col>
        </Row>
      )}
      {uiState.showInkludertILeien && ( // idrettsanlegg og lokaler
        <Row>
          <Col md={10}>
            <BymTextInput
              componentClass="textarea"
              controlId="avtaleData.inkludertILeien"
              placeholder="Begynn å skrive her ..."
              label="Inkludert i leien"
              rows={3}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default TimeLeiePrisInfo;
