import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { BymForm, BymSubmitButton, BymTextInput, length, required } from "components/Common/Forms";
import KontraktEditor from "./KontraktEditor";
import BlobLink from "components/Common/BlobLink";
import useKoder from "hooks/useKoder";
import { GET_KODER_URL } from "../../constants/api";
import dokumentIcon from "images/dokument.svg";
import PageSection from "components/Layout/PageSection";
import "./AddKontraktSpecialDialog.css";

type AddKontraktSpecialDialogProps = {
  show: boolean;
  onHide: () => void;
  [key: string]: any;
};

const AddKontraktSpecialDialog = ({ show, onHide = () => null, ...rest }: AddKontraktSpecialDialogProps) => {
  const [showSpecialReasonDialog, setShowSpecialReasonDialog] = useState(true);
  const [reasonText, setReasonText] = useState("");
  const koder = useKoder();

  const formSubmit = (e: { reason: string }) => {
    setReasonText(e.reason);
    setShowSpecialReasonDialog(false);
  };

  const availableKontraktMaler = koder.kontraktMaler.filter(km => !!km.wordMal);

  return (
    <Modal
      onHide={onHide}
      show={show}
      bsSize="large"
      dialogClassName="add-kontrakt-special-dialog"
      allowFullScreen
      backdrop="static"
    >
      <ModalHeader closeButton>
        <ModalTitle>Bruk av særavtale</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {showSpecialReasonDialog && (
          <BymForm values={{ reason: undefined }} onSubmit={formSubmit} id="kontrakt-special-form">
            <p>
              Særavtaler skal kun brukes der det er nødvendig, og der standardavtalene ikke er tilstrekkelig for å
              regulere leieforholdet. Oppdaterte standardmaler finner du i Word-format under, og bør brukes som
              utgangspunkt for særavtaler. Det er heller ikke nødvendig og heller ikke anbefalt å lagre flere kopier på
              din pc for senere bruk. Særavtaler skal til gjennomgang hos Juridisk avdeling, før avtaleforslaget
              oversendes søker.
            </p>
            <BymTextInput
              componentClass="textarea"
              controlId="reason"
              label="Særavtale grunn"
              hidelabel={true}
              placeholder="Skriv inn grunnen til at det må brukes særavtale (minimum 6 tegn)"
              rows={3}
              rules={[required(), length(6, 512)]}
            />
            {availableKontraktMaler.length == 0 && (
              <>
                <strong>Feil:</strong>
                <p>
                  Ingen maler med verdi for &quot;Word mal&quot; finnes. Gå til Koder &rarr; KontraktMal i
                  admin-grensesnittet for å definere disse.
                </p>
              </>
            )}
            {availableKontraktMaler.length > 0 && (
              <PageSection title="Last ned wordmal">
                <strong>Tips til utfylling:</strong>
                <p>
                  Klammefelter (markert med […]) skal fylles inn i henhold til sin forklaring (eks.
                  [faktureringsfrekvens]) eller på bakgrunn av sin kontekst (eks. total leiesum for forholdet er […]).
                  Alternative bestemmelser er markert med en rødt A eller B (eks.{" "}
                  <span style={{ color: "red" }}>A</span>). Her er det viktig at du kun velger ett av alternativene,
                  ettersom A/B alternativene ikke kan benyttes sammen, og er selvmotsigende dersom begge legges inn i en
                  kontrakt. I enkelte kontrakter vil du også finne punkter markert med en rød alt (eks.{" "}
                  <span style={{ color: "red" }}>Alt</span>). Disse punktene kan legges inn dersom du har behov for det,
                  men hvis du ikke har det, bør disse punktene slettes.
                </p>
                <p>
                  Vi anbefaler for øvrig at du bruker funksjonen &quot;Spor endringer&quot; for å sørge for oversikt
                  over de endringene du har gjort i standardmalene når du lager en særavtale. Dersom du ikke bruker
                  &quot;Spor endringer&quot;, må du notere endringene du gjør i et eget separat dokument eller i en
                  e-post, som du sender til juridisk avdeling. Særavtalen skal sendes Juridisk avdeling til gjennomgang,
                  hovedsakelig via arkivet. Hvis du vedlegger endringene dine i et separat dokument, eller sender dem
                  per e-post, bør du henvise til arkivnummeret i emnefeltet.
                </p>
                <ul className="list-unstyled vedlegg-list">
                  {availableKontraktMaler.map(kt => (
                    <li key={kt.id}>
                      <img src={dokumentIcon} alt="" />
                      <BlobLink className="btn btn-link" href={`${GET_KODER_URL}kontraktmaler/${kt.id}/word`}>
                        {kt.wordMal}
                      </BlobLink>
                    </li>
                  ))}
                </ul>
              </PageSection>
            )}
            <BymSubmitButton className="submit-special-button" text="Ny særavtale" />
          </BymForm>
        )}
        {!showSpecialReasonDialog && (
          <KontraktEditor kontraktTemplate={0} saerAvtaleGrunn={reasonText} {...koder} {...rest} onCancel={onHide} />
        )}
      </ModalBody>
    </Modal>
  );
};

export default AddKontraktSpecialDialog;
