import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ExpandableDetailsList from "../Common/ExpandableDetailsList";
import BrukerDetails from "./BrukerDetails";
import { getKoder } from "../../actions/koderAction";
import koderSelector from "../../selectors/koderSelector";
import { sortBy } from "lodash-es";
import { Button } from "react-bootstrap";

class BrukerAdmin extends React.Component {
  static defaultProps = {
    brukere: []
  };

  componentDidMount() {
    const { getKoder } = this.props;
    getKoder("segmenter");
  }

  onUpdateBruker = async bruker => {
    const { editBruker, setToastSuccess, setToastError } = this.props;
    try {
      await editBruker(bruker.brukerId, bruker);
      setToastSuccess("Bruker ble oppdatert");
    } catch (e) {
      setToastError(e);
    }
  };

  makeBrukerDetails = bruker => {
    const { segmenter } = this.props;

    return <BrukerDetails {...bruker} segmenter={segmenter} onUpdate={this.onUpdateBruker} />;
  };

  render() {
    const { brukere, getBrukere } = this.props;
    return (
      <>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button onClick={() => getBrukere(true)}>Oppdater brukere</Button>
        </div>
        <div>
          <div style={{ marginBottom: "1em" }}>
            Merk: brukere legges til i OneLogin. Seksjonstilhørighet settes her.
          </div>
          <ExpandableDetailsList
            makeDetailsComponent={this.makeBrukerDetails}
            items={brukere ? sortBy(brukere, x => x.navn.toLowerCase()) : []}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return { segmenter: koderSelector(state, "segmenter") || [] };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getKoder }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BrukerAdmin);
