import React from "react";
import { BymTextInput, BymNumberInput, BymCheckboxInput, BymButton, required } from "components/Common/Forms";
import { map, filter } from "lodash-es";
import "./TimeleieObjekter.css";
import { TimeleieType } from "./KontraktAvtaleUtils";
import BymCurrencyInput from "components/Common/Forms/BymCurrencyInput";

export type TimeleieObjekterProps = {
  kontraktLeieobjekter?: any[];
  allLeieobjekter: any[];
  showPrisInfo?: boolean;
  useTotalPris?: boolean;
  showDepositum?: boolean;
  timeleieType?: TimeleieType;
};

const TimeleieObjekter = ({
  kontraktLeieobjekter = [],
  allLeieobjekter,
  showPrisInfo,
  useTotalPris,
  showDepositum,
  timeleieType
}: TimeleieObjekterProps) => {
  const checkedItems = filter(kontraktLeieobjekter, (i: any) => i.checked);
  if (checkedItems?.length === 0) return <></>;
  const isChecked = (leieobjektId: any) =>
    filter(checkedItems, (item: any) => item.leieObjektId === leieobjektId).length > 0;
  return (
    <table className="timeleie-table">
      <thead>
        <tr>
          <th>Leieobjekt</th>
          {timeleieType === "timeleieGarderobe" && <th>Har garderobe</th>}
          {timeleieType === "timeleieBetjening" && (
            <>
              <th>Betjent</th>
              <th>Vederlagsfri</th>
            </>
          )}
          <th>{`${timeleieType === "timeleie" ? "Fra dato kl." : "Fra kl."}`}</th>
          <th>{`${timeleieType === "timeleie" ? "Til dato kl." : "Til kl."}`}</th>
          {showPrisInfo && (
            <>
              <th>Dag</th>
              <th>Timer</th>
              <th>Antall ganger</th>
              {timeleieType === "timeleieBetjening" && (
                <>
                  <th>Leie bassengtid</th>
                  <th>Betjenings&shy;kostnad</th>
                </>
              )}
              <th>Leiesum</th>
            </>
          )}
          {showDepositum && <th>Depositum</th>}
        </tr>
      </thead>
      <tbody>
        {map(allLeieobjekter, (i: any, index) => {
          if (!isChecked(i.id)) return null;

          const kontraktLeieobjekt = kontraktLeieobjekter[index];

          return map(kontraktLeieobjekt?.timeLeie, (timeleie: any, timeleieIndex: number) => {
            return (
              <tr>
                <td>
                  {timeleieIndex === 0 && (
                    <BymTextInput controlId={`kontrakt.leieObjekter[${index}].navn`} hidelabel readOnly />
                  )}
                </td>
                {timeleieType === "timeleieGarderobe" && (
                  <td>
                    {timeleieIndex === 0 && (
                      <BymCheckboxInput controlId={`kontrakt.leieObjekter[${index}].harGarderobe`} hidelabel inline />
                    )}
                  </td>
                )}
                {timeleieType === "timeleieBetjening" && (
                  <>
                    <td>
                      {timeleieIndex === 0 && (
                        <BymCheckboxInput controlId={`kontrakt.leieObjekter[${index}].harBetjening`} hidelabel inline />
                      )}
                    </td>
                    <td>
                      {timeleieIndex === 0 && (
                        <BymCheckboxInput controlId={`kontrakt.leieObjekter[${index}].vederlagsfri`} hidelabel inline />
                      )}
                    </td>
                  </>
                )}
                <td>
                  <BymTextInput
                    controlId={`kontrakt.leieObjekter[${index}].timeLeie[${timeleieIndex}].fraKl`}
                    hidelabel
                    rules={[required()]}
                  />
                </td>
                <td>
                  <BymTextInput
                    controlId={`kontrakt.leieObjekter[${index}].timeLeie[${timeleieIndex}].tilKl`}
                    hidelabel
                    rules={[required()]}
                  />
                </td>
                {showPrisInfo && (
                  <>
                    <td>
                      <BymTextInput
                        controlId={`kontrakt.leieObjekter[${index}].timeLeie[${timeleieIndex}].dag`}
                        hidelabel
                        rules={[required()]}
                      />
                    </td>
                    <td>
                      <BymNumberInput
                        controlId={`kontrakt.leieObjekter[${index}].timeLeie[${timeleieIndex}].timer`}
                        hidelabel
                        rules={[required()]}
                      />
                    </td>
                    <td>
                      <BymNumberInput
                        controlId={`kontrakt.leieObjekter[${index}].timeLeie[${timeleieIndex}].antallGanger`}
                        hidelabel
                        rules={[required()]}
                      />
                    </td>
                    {timeleieType === "timeleieBetjening" && (
                      <>
                        <td>
                          <BymCurrencyInput
                            controlId={`kontrakt.leieObjekter[${index}].timeLeie[${timeleieIndex}].leieBassengtid`}
                            hidelabel
                            rules={[required()]}
                          />
                        </td>
                        <td>
                          <BymCurrencyInput
                            controlId={`kontrakt.leieObjekter[${index}].timeLeie[${timeleieIndex}].betjeningskostnad`}
                            hidelabel
                            rules={[required()]}
                          />
                        </td>
                      </>
                    )}
                    <td>
                      <BymCurrencyInput
                        controlId={`kontrakt.leieObjekter[${index}].timeLeie[${timeleieIndex}].totalPris`}
                        hidelabel
                        className={`${useTotalPris ? "" : "hidden"}`}
                        rules={useTotalPris ? [required()] : []}
                      />
                      <BymCurrencyInput
                        controlId={`kontrakt.leieObjekter[${index}].timeLeie[${timeleieIndex}].pris`}
                        hidelabel
                        className={`${useTotalPris ? "hidden" : ""}`}
                        rules={useTotalPris ? [] : [required()]}
                      />
                    </td>
                    {showDepositum && (
                      <td>
                        <BymCurrencyInput
                          controlId={`kontrakt.leieObjekter[${index}].timeLeie[${timeleieIndex}].depositum`}
                          hidelabel
                          rules={[required()]}
                        />
                      </td>
                    )}
                  </>
                )}
                {timeleieIndex === 0 && (
                  <td>
                    <BymButton
                      bsStyle="link"
                      controlId="avtaleData.addTimeleieLinje"
                      data-leieobjektid={kontraktLeieobjekt.leieObjektId}
                      title="Legg til timeleie linje"
                      style={{ fontSize: "1.4em" }}
                    >
                      +
                    </BymButton>
                  </td>
                )}
                {timeleieIndex > 0 && (
                  <td>
                    <BymButton
                      bsStyle="link"
                      controlId="avtaleData.removeTimeleieLinje"
                      data-leieobjektid={kontraktLeieobjekt.leieObjektId}
                      data-timeleieindex={timeleieIndex}
                      title="Fjern timeleie linje"
                      style={{ fontSize: "1.4em" }}
                    >
                      -
                    </BymButton>
                  </td>
                )}
              </tr>
            );
          });
        })}
      </tbody>
    </table>
  );
};

export default TimeleieObjekter;
