import { getKontraktLogg } from "actions/kontraktAction";
import LoggSection from "components/Logg/LoggSection";
import { LoggHendelse } from "interfaces/LoggHendelse";
import { useEffect, useState } from "react";

const KontraktLogg = ({ kontraktId }: { kontraktId: string }) => {
  const [logg, setLogg] = useState<LoggHendelse[]>([]);
  useEffect(() => {
    getKontraktLogg(kontraktId).then(r => setLogg(r));
  }, []);

  return (
    <LoggSection
      logg={logg as never[]}
      onUndo={() => {}}
      kostSteder={[]}
      kontoNummere={[]}
      kontraktTyper={[]}
      prisTyper={[]}
      kontraktStatuser={[]}
      faktureringTyper={[]}
      betalingTerminTyper={[]}
      faktureringTidspunkter={undefined}
    />
  );
};

export default KontraktLogg;
