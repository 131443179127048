import { useState, useRef } from "react";
import { Modal, ModalHeader, ModalTitle, ModalBody, Row, Col, Alert } from "react-bootstrap";
import { VerticalTabs, VerticalTabContent } from "components/Common/VerticalTabs/VerticalTabs";
import { ReactComponent as EiendomIcon } from "images/eiendom2.svg";
import {
  BymForm,
  BymTextInput,
  BymNumberInput,
  BymOrgNumberInput,
  BymRadioButtonsInput,
  BymCheckboxInput,
  BymEmailInput,
  BymSpinnerButton,
  BymCustomValidator,
  length,
  required
} from "components/Common/Forms";
import { Eiendom, Kontrakt, LeieObjekt, KontraktLeieObjekt } from "interfaces";
import useActions from "store/useActions";
import AddLeieObjektDialog from "components/LeieObjekt/AddLeieObjektDialog";
import { useSelector } from "react-redux";
import { ReactComponent as LeieObjektIcon } from "images/leieobjekt.svg";
import BymButton from "components/Common/Forms/BymButton";
import BymFormValueWrapper from "components/Common/Forms/BymFormValueWrapper";
import BymFormConditional from "components/Common/Forms/BymFormConditional";
import axios from "services/axios";
import { filter, get, includes, pullAt, forIn } from "lodash-es";
import { produce, setAutoFreeze } from "immer";
import moment from "moment";
import { ReactComponent as EditIcon } from "images/rediger.svg";
import { ReactComponent as CopyIcon } from "images/content-copy.svg";
import { ReactComponent as PilHoyreIcon } from "images/pil-høyre.svg";
import * as leieObjektAction from "../../actions/leieObjektAction";
import { getKoder } from "../../actions/koderAction";
import getKoderSelector from "../../selectors/koderSelector";
import { setToastSuccess } from "../../actions/toastAction";
import * as api from "../../constants/api";
import "./AddKontraktAvtaleDialog.css";
import AttachmentList from "./KontraktAvtale/AttachmentList";
import TemplateChooser from "./KontraktAvtale/TemplateChooser";
import KontraktName from "./KontraktAvtale/KontraktName";
import { useKontraktAvtale, AvtaleData } from "./KontraktAvtale/KontraktAvtaleUtils";
import FakturaInformasjon from "./KontraktAvtale/FakturaInformasjon";
import DownloadAvtaleButton from "./KontraktAvtale/DownloadAvtaleButton";
import useKoder from "hooks/useKoder";
import { OpprettKontraktTab } from "./KontraktAvtale/OpprettKontraktTab";

type AddKontraktAvtaleDialogProps = {
  show: boolean;
  onHide: () => void;
  onSave: (kontrakt: any, closeDialog?: boolean) => Promise<any>;
  eiendom?: Eiendom;
  leieObjekter?: Array<LeieObjekt & { checked?: boolean }>;
  kontraktData?: Kontrakt;
};

const AddKontraktAvtaleDialog = ({
  show,
  onHide,
  onSave,
  eiendom,
  leieObjekter = [],
  kontraktData
}: AddKontraktAvtaleDialogProps) => {
  const {
    kontraktTemplates,
    fremLeieOptions,
    tvistOptions,
    initialAvtaleData,
    templateIsSelected,
    isEditMode,
    uiState,
    changeTemplate
  } = useKontraktAvtale(kontraktData, eiendom, leieObjekter);

  const [showAddLeieObjektDialog, setShowAddLeieObjektDialog] = useState(false);
  const [editLeieObjekt, setEditLeieObjekt] = useState<any>();
  const [showLeietakerSpinner, setShowLeietakerSpinner] = useState(false);
  const [showAddKontraktSpinner, setShowAddKontraktSpinner] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [kundeErrorMessage, setKundeErrorMessage] = useState("");
  const nyttLeieobjektResolverRef = useRef<any>(null);
  const { kontraktTyper, faktureringTyper, kostSteder, kontoNummere } = useKoder();

  const vernTyper = useSelector(state => getKoderSelector(state, "verntyper"));
  const leieObjektTyper = useSelector(state => getKoderSelector(state, "leieobjekttyper"));
  const actions = useActions(
    {
      ...leieObjektAction,
      getKoder,
      setToastSuccess
    },
    []
  );

  const kostStedSelectList = kostSteder?.map(k => ({ ...k, navn: `${k.id}: ${k.navn}` }));
  const kontoSelectList = kontoNummere?.map(k => ({ ...k, navn: `${k.id}: ${k.navn}` }));

  const getPrefiksArealetMaaBareBrukesTil = (kontraktTemplateId?: string): string => {
    switch (kontraktTemplateId) {
      case "1": // leie av areal
        return "Arealet må bare benyttes til";
      case "2": // leie av riggplass
        return "Leieobjektet må bare benyttes til";

      case "7": // leie av hytte
        return "Arealet må bare benyttes til allmennyttige formål, herunder";

      case "3": // leie av lokale på eiendom, flere brukere
      case "9": // leie av lokale med en bruker
        return "Leieobjektet må kun benyttes til";
    }

    // leiekontrakt - bruk av bad
    // timeleie idrettsanlegg
    // timeleie lokaler
    return "Leieobjektet skal kun brukes til";
  };

  const supportsMultipleLeieobjekter = (kontraktTemplateId?: string) =>
    includes(["2", "4", "5", "6"], kontraktTemplateId);

  const leieObjektValidator = (values: AvtaleData, includeBygningsnummer = false) => {
    const kontraktLeieObjekter: any[] = values.kontrakt?.leieObjekter?.filter?.((l: any) => l.checked) || [];

    if (kontraktLeieObjekter.length === 0) {
      return "Velg leieobjekt er påkrevd";
    }

    if (kontraktLeieObjekter.length > 1 && !supportsMultipleLeieobjekter(values.avtaleData.kontraktsMal)) {
      return "Kan kun velge 1 leieobjekt";
    }

    if (kontraktLeieObjekter && kontraktLeieObjekter.length === 1) {
      const selectedLeieObjekt = leieObjekter.filter(l => l.id == kontraktLeieObjekter[0].leieObjektId);
      const bygningsnummerCheck =
        !includeBygningsnummer || (includeBygningsnummer && selectedLeieObjekt?.[0].bygningsnummer);
      if (
        selectedLeieObjekt?.[0]?.adresse2 &&
        selectedLeieObjekt?.[0]?.bruksAreal &&
        selectedLeieObjekt?.[0]?.gnr &&
        selectedLeieObjekt?.[0]?.bnr &&
        bygningsnummerCheck
      ) {
        return null;
      }
      return 'Valgt leieobjekt må ha "Adresse", "Bruksareal", "G.nr" og "B.nr"';
    }
    return null; // return "Velg leieobjekt er påkrevd. Kan kun velge 1 leieobjekt";
  };

  const saveLeieObjekt = async (leieObjekt: LeieObjekt) => {
    setShowAddLeieObjektDialog(false);
    const leieobjektResult = await actions.postLeieObjekt(eiendom?.id, leieObjekt);
    const leieobjektWithId = {
      ...leieObjekt,
      id: leieobjektResult.result
    };
    actions.setToastSuccess("Leieobjekt lagt til");
    if (nyttLeieobjektResolverRef.current) {
      nyttLeieobjektResolverRef.current(leieobjektWithId);
    }
  };

  const updateLeieObjekt = async (leieObjekt: LeieObjekt) => {
    setShowAddLeieObjektDialog(false);
    setEditLeieObjekt(undefined);
    await actions.editLeieObjekt(eiendom?.id, leieObjekt?.id, leieObjekt);
    actions.setToastSuccess("Leieobjekt oppdatert");
  };

  const saveKontrakt = async (avtaleData: AvtaleData) => {
    const checkedLeieObjekter = avtaleData?.kontrakt?.leieObjekter?.filter(l => l.checked);
    if (checkedLeieObjekter?.[0] && avtaleData.avtaleData?.aarligSum) {
      checkedLeieObjekter[0].pris = avtaleData.avtaleData?.aarligSum;
      checkedLeieObjekter[0].antall = 1;
    }

    if (isEditMode) {
      setShowDownloadButton(true);
      return;
    }

    setShowAddKontraktSpinner(true);
    await onSave(
      {
        ...avtaleData?.kontrakt,
        kontraktMalId: avtaleData?.avtaleData?.kontraktsMal,
        leieObjekter: checkedLeieObjekter
      },
      false
    );
    setShowAddKontraktSpinner(false);
    setShowDownloadButton(true);
  };

  const opprettKundeInFront = async (orgOrSocialSecurityNumber: any) => {
    const result = await axios.post(api.POST_KUNDE_URL, {
      publicRegistryIdentifier: orgOrSocialSecurityNumber
    });

    return result;
  };

  const hentLeietakerInfo = async (formData: any) => {
    let result: any;
    setShowLeietakerSpinner(true);
    if (formData.avtaleData.leietakerType === "Organisasjonsnummer") {
      result = await axios.get(api.GET_KUNDE_ORGANISASJON_URL(formData?.kontrakt?.organisasjonsnummer));
      if (result?.data?.result?.customerNumber === null) {
        result = await opprettKundeInFront(result?.data?.result?.organizationNumber);
      }
    } else if (formData.avtaleData.leietakerType === "Personnummer") {
      result = await axios.get(api.GET_KUNDE_PRIVAT_URL(formData?.kontrakt?.fodselsnummer));
      if (result?.data?.result?.customerNumber === null) {
        result = await opprettKundeInFront(result?.data?.result?.socialSecurityNumber);
      }
    } else if (formData.avtaleData.leietakerType === "Kundenummer") {
      result = await axios.get(api.GET_KUNDE_URL(formData?.kontrakt?.kundenummer));
    }
    if (result.status === 204 || result.status === 400) {
      setKundeErrorMessage("Ugyldig organisasjonsnummer/personnummer/kundenummer");
    } else {
      setKundeErrorMessage("");
    }
    setShowLeietakerSpinner(false);
    const newFormData = {
      avtaleData: {
        ...formData.avtaleData,
        leietakerNavn: result?.data?.result?.name ?? "",
        leietakerAdresse: get(result?.data?.result, "addresses[0].street") ?? "",
        leietakerPostnummer: get(result?.data?.result, "addresses[0].postNumber") ?? "",
        leietakerPoststed: get(result?.data?.result, "addresses[0].location") ?? "",
        leietakerTelefon: get(result?.data?.result, "krrContactInformation.mobile") ?? "",
        leietakerEpost: get(result?.data?.result, "krrContactInformation.email") ?? ""
      },
      kontrakt: {
        ...formData.kontrakt,
        organisasjonsnummer: result?.data?.result?.organizationNumber,
        fodselsnummer: result?.data?.result?.socialSecurityNumber,
        kundenummer: result?.data?.result?.customerNumber
      }
    };
    return newFormData;
  };

  const getStandardBadVedlegg = (formAvtaleData: AvtaleData) => {
    const hasUbetjentLeieobjekt =
      filter(formAvtaleData?.kontrakt?.leieObjekter, (l: any) => l.checked && !l.harBetjening).length > 0;

    return hasUbetjentLeieobjekt
      ? [
          { name: "Brann- og rømningsinstruks", allowDelete: false },
          { name: "Rutine ved bruk av badeanlegg", allowDelete: false },
          { name: "Ansvarserklæring ved leie av bad", allowDelete: false }
        ]
      : [{ name: "Brann- og rømningsinstruks", allowDelete: false }];
  };

  // set immer autofreeze to false. BymForm needs change before we can use freeze
  setAutoFreeze(false);

  const onFormChange = (propertyName: string, formAvtaleData: AvtaleData, target: HTMLInputElement) => {
    const updatedAvtaleData: any = produce<AvtaleData>(formAvtaleData, draft => {
      const command =
        propertyName?.includes("harBetjening") ||
        (propertyName?.includes("leieObjekter") && formAvtaleData.avtaleData.kontraktsMal === "6")
          ? "badvedlegg"
          : propertyName;

      switch (command) {
        case "nyttLeieobjektButton":
          setShowAddLeieObjektDialog(true);
          // return Promise that will be resolved when a new leieobjekt is saved in "saveLeieObjekt"
          return new Promise(resolve => {
            nyttLeieobjektResolverRef.current = (nyttLeieobjekt: LeieObjekt) => {
              const newKontraktLeieobjekt: KontraktLeieObjekt = {
                leieObjektId: nyttLeieobjekt.id,
                navn: nyttLeieobjekt.navn,
                pris: 0,
                antall: 1,
                checked: false,
                timeLeie: [{}]
              };
              const avtaleData = {
                ...formAvtaleData
              };
              if (avtaleData && avtaleData.kontrakt) {
                avtaleData.kontrakt.leieObjekter = [...(avtaleData.kontrakt.leieObjekter || []), newKontraktLeieobjekt];
              }
              resolve(avtaleData);
            };
          });
        case "avtaleData.kontraktsMal":
          return changeTemplate(formAvtaleData);

        case "avtaleData.hentLeietakerInfo":
          return hentLeietakerInfo(formAvtaleData);

        case "avtaleData.leietakerIsKontaktperson":
          draft.avtaleData.kontaktpersonAdresse = formAvtaleData?.avtaleData?.leietakerAdresse;
          draft.kontrakt.kontaktpersonTelefon = formAvtaleData?.avtaleData?.leietakerTelefon ?? undefined;
          draft.kontrakt.kontaktpersonNavn = formAvtaleData?.avtaleData?.leietakerNavn ?? undefined;
          draft.kontrakt.kontaktpersonEpost = formAvtaleData?.avtaleData?.leietakerEpost ?? undefined;
          break;

        case "avtaleData.totalSum":
        case "kontrakt.gyldigFraDato":
          draft.avtaleData.aarligSum = beregnAarligSum(formAvtaleData);
          break;

        case "kontrakt.betalingTerminId":
        case "avtaleData.aarligSum":
          // calculate fakturaSum for leie av hytte/bolig
          if (formAvtaleData.avtaleData.kontraktsMal === "7" || formAvtaleData.avtaleData.kontraktsMal === "8") {
            draft.avtaleData.fakturaSum = beregnFakturaSum(
              draft.avtaleData.aarligSum,
              draft.kontrakt.betalingTerminId as any
            );
          }
          break;

        case "kontrakt.utlopDato":
          if (!formAvtaleData.kontrakt?.utlopDato && formAvtaleData.avtaleData.leiesumType === "2") {
            draft.avtaleData.leiesumType = "1";
          }
          draft.avtaleData.aarligSum = beregnAarligSum(formAvtaleData);
          break;

        case "avtaleData.addTimeleieLinje":
          if (target.dataset?.leieobjektid) {
            const kontraktLeieObjekt = draft?.kontrakt?.leieObjekter?.filter(
              lo => lo.leieObjektId === target.dataset.leieobjektid
            )[0];
            if (kontraktLeieObjekt && kontraktLeieObjekt.timeLeie) {
              kontraktLeieObjekt.timeLeie.push({});
            }
          }
          break;

        case "avtaleData.removeTimeleieLinje":
          if (target.dataset?.leieobjektid) {
            const kontraktLeieObjekt = draft?.kontrakt?.leieObjekter?.filter(
              lo => lo.leieObjektId === target.dataset.leieobjektid
            )[0];
            if (kontraktLeieObjekt && kontraktLeieObjekt.timeLeie) {
              const index = Number(target?.dataset?.timeleieindex);
              pullAt(kontraktLeieObjekt.timeLeie, [index]);
            }
          }
          break;

        case "avtaleData.leiesumType":
          // reset aarspris when choosing totalpris
          if (draft.avtaleData.leiesumType === "2") {
            forIn(draft?.kontrakt?.leieObjekter, (l: KontraktLeieObjekt) => {
              // eslint-disable-next-line no-param-reassign
              l.pris = undefined;
            });
          }
          break;

        case "badvedlegg":
          draft.avtaleData.vedlegg = getStandardBadVedlegg(formAvtaleData);
          break;

        default:
      }
    });

    if (updatedAvtaleData !== formAvtaleData) {
      return updatedAvtaleData;
    }
    return undefined;
  };

  const beregnFakturaSum = (aarligSum: string, betalingTermin?: string) => {
    if (!aarligSum || !betalingTermin) return undefined;
    let divider = 12; // defalt månedlig
    if (betalingTermin === "2") divider = 4; // kvartalsvis
    if (betalingTermin === "3") divider = 2; // halvårlig
    if (betalingTermin === "4") divider = 1; // årlig
    return Number((Number(aarligSum) / divider).toFixed(2));
  };

  const beregnAarligSum = (formAvtaleData: AvtaleData): number | undefined => {
    if (
      formAvtaleData?.kontrakt?.gyldigFraDato &&
      formAvtaleData?.kontrakt?.utlopDato &&
      formAvtaleData?.avtaleData?.totalSum
    ) {
      // const newAvtaleData = { ...formAvtaleData };
      const start = moment(formAvtaleData.kontrakt?.gyldigFraDato);
      const end = moment(formAvtaleData?.kontrakt?.utlopDato);
      const totalSum = formAvtaleData?.avtaleData?.totalSum;
      const lengthInMonths = end.add("days", 1).diff(start, "month", true);
      const aarligSum = Math.round((totalSum / lengthInMonths) * 12);
      return aarligSum;
    }
    return formAvtaleData?.avtaleData?.aarligSum;
  };

  return (
    <Modal
      onHide={onHide}
      show={show}
      bsSize="lg"
      dialogClassName="kontraktsavtale-dialog"
      allowFullScreen
      backdrop="static"
    >
      {showAddLeieObjektDialog && (
        <AddLeieObjektDialog
          vernTyper={vernTyper}
          leieObjektTyper={leieObjektTyper}
          show
          editObject={editLeieObjekt}
          onHide={() => setShowAddLeieObjektDialog(false)}
          onSave={saveLeieObjekt}
          onEdit={updateLeieObjekt}
        />
      )}

      <BymForm values={initialAvtaleData} onChange={onFormChange} onSubmit={saveKontrakt}>
        <ModalHeader closeButton style={{ backgroundColor: "#f8fbfd ", padding: "20px 15px" }}>
          <ModalTitle>Ny kontraktsavtale</ModalTitle>
          <h5>
            <EiendomIcon width="24" stroke="#333333" stroke-width="4" fill="#fef6e6" />
            &nbsp; {eiendom?.Navn} : &nbsp;
            <BymFormValueWrapper controlId="kontrakt.tittel" />
            <BymFormConditional<AvtaleData> comparator={formValues => formValues?.kontrakt?.tittel === undefined}>
              uten tittel
            </BymFormConditional>
          </h5>
          <BymFormConditional<AvtaleData>
            comparator={formValues => formValues?.avtaleData?.kontraktsMal !== undefined && !showDownloadButton}
          >
            <DownloadAvtaleButton className="pull-right" preview>
              Forhåndsvisning
            </DownloadAvtaleButton>
          </BymFormConditional>
          <div style={{ clear: "left" }}>
            <strong>Kontraktsmal:</strong> <BymFormValueWrapper controlId="avtaleData.kontraktsMalLabel" />
          </div>
        </ModalHeader>
        <ModalBody style={{ padding: "0", minHeight: "600px", display: "flex", flexDirection: "column" }}>
          <VerticalTabs>
            {isEditMode && (
              <VerticalTabContent
                disabled={showDownloadButton}
                title="Nytt kontraktsdokument"
                showNextButton={templateIsSelected}
              >
                <Row>
                  <Col md={9}>
                    <p>
                      Du har valgt å generere et nytt kontraktdokument. Data fra kontrakten er allerede fylt inn, men
                      noen av dataene fra opprinnelig kontraktdokument må legges inn på nytt
                    </p>
                    <Alert bsStyle="warning">
                      <h4>Advarsel</h4>
                      <p>Endringer som gjøres her vil kun gjelde for det genererte dokumentet</p>
                      <p>
                        Hvis du ønsker å gjøre endringer på selve kontrakten trykk &quot;Rediger&quot; på kontrakten i
                        stedet
                      </p>
                    </Alert>
                  </Col>
                </Row>
              </VerticalTabContent>
            )}
            <VerticalTabContent
              disabled={showDownloadButton}
              title="Velg kontraktsmal"
              showNextButton={templateIsSelected}
            >
              <TemplateChooser kontraktTemplates={kontraktTemplates} />
            </VerticalTabContent>
            <VerticalTabContent disabled={!templateIsSelected || showDownloadButton} title="Registrer kontraktsnavn">
              <KontraktName
                kostSteder={kostStedSelectList}
                kontoNummere={kontoSelectList}
                kontraktTyper={kontraktTyper}
                faktureringTyper={faktureringTyper}
                showTittelFormaal={uiState.showTittelFormaal}
                showTittelNavn={uiState.showTittelNavn}
                showSesongNavn={uiState.showSesongNavn}
              />
            </VerticalTabContent>
            <VerticalTabContent disabled={!templateIsSelected || showDownloadButton} title="Ansvarlig leietaker">
              <Row>
                <Col md={12}>
                  <BymRadioButtonsInput
                    inline
                    hidelabel
                    controlId="avtaleData.leietakerType"
                    items={[
                      { id: "Organisasjonsnummer", name: "Organisasjonsnummer" },
                      { id: "Personnummer", name: "Personnummer" },
                      { id: "Kundenummer", name: "Kundenummer" }
                    ]}
                    rules={[required()]}
                  />
                </Col>
              </Row>
              <Row>
                <BymFormConditional<AvtaleData>
                  comparator={formValues => formValues?.avtaleData?.leietakerType !== undefined}
                >
                  <Col md={2}>
                    <BymFormConditional<AvtaleData>
                      comparator={formValues => formValues?.avtaleData?.leietakerType === "Organisasjonsnummer"}
                    >
                      <BymOrgNumberInput controlId="kontrakt.organisasjonsnummer" label="*Organisasjonsnummer" />
                    </BymFormConditional>
                    <BymFormConditional<AvtaleData>
                      comparator={formValues => formValues?.avtaleData?.leietakerType === "Personnummer"}
                    >
                      <BymNumberInput controlId="kontrakt.fodselsnummer" label="*Personnummer" />
                    </BymFormConditional>
                    <BymFormConditional<AvtaleData>
                      comparator={formValues => formValues?.avtaleData?.leietakerType === "Kundenummer"}
                    >
                      <BymNumberInput
                        controlId="kontrakt.kundenummer"
                        label="*Kundenummer"
                        rules={[length(5, 8, false)]}
                      />
                    </BymFormConditional>
                  </Col>

                  <Col md={3}>
                    <BymSpinnerButton
                      style={{ marginTop: "38px", marginLeft: "-20px", float: "left" }}
                      bsStyle="bym-action"
                      controlId="avtaleData.hentLeietakerInfo"
                      text="Hent info"
                      showSpinner={showLeietakerSpinner}
                    />
                  </Col>
                </BymFormConditional>
              </Row>
              <Row>
                <Col md={12}>
                  <div style={{ color: "#a94442", marginTop: "10px" }}>{kundeErrorMessage}</div>
                </Col>
              </Row>
              <BymFormConditional comparator={(formValues: any) => formValues?.avtaleData?.leietakerType !== undefined}>
                <Row>
                  <Col md={6}>
                    <BymTextInput controlId="avtaleData.leietakerNavn" label="*Navn" rules={[required()]} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <BymEmailInput controlId="avtaleData.leietakerEpost" label="*Epost" rules={[required()]} />
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <BymNumberInput controlId="avtaleData.leietakerTelefon" label="*Telefon" rules={[required()]} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <BymTextInput controlId="avtaleData.leietakerAdresse" label="*Adresse" rules={[required()]} />
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <BymNumberInput
                      controlId="avtaleData.leietakerPostnummer"
                      label="*Postnummer"
                      rules={[required()]}
                    />
                  </Col>
                  <Col md={3}>
                    <BymTextInput controlId="avtaleData.leietakerPoststed" label="*Poststed" rules={[required()]} />
                  </Col>
                </Row>
              </BymFormConditional>
            </VerticalTabContent>
            <VerticalTabContent disabled={!templateIsSelected || showDownloadButton} title="Registrer kontaktperson">
              <Row>
                <Col md={6} style={{ margin: "4px 0 4px -12px" }}>
                  <BymButton bsStyle="link" controlId="avtaleData.leietakerIsKontaktperson">
                    <CopyIcon height="18px" width="18px" fill="#0071bb" style={{ marginBottom: "-3px" }} /> Kopier fra
                    leietaker
                  </BymButton>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <BymTextInput
                    controlId="kontrakt.kontaktpersonNavn"
                    label="*Navn"
                    rules={[required(), length(3, 100, false)]}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <BymTextInput controlId="avtaleData.kontaktpersonAdresse" label="*Adresse" rules={[required()]} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <BymEmailInput controlId="kontrakt.kontaktpersonEpost" label="*Epost" rules={[required()]} />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <BymNumberInput controlId="kontrakt.kontaktpersonTelefon" label="*Telefon" rules={[required()]} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <hr />
                  <h4>Kontaktperson BYM</h4>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <BymTextInput
                    controlId="avtaleData.kontaktpersonBymNavn"
                    label="*Navn"
                    rules={[required(), length(3, 100, false)]}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <BymEmailInput controlId="avtaleData.kontaktpersonBymEpost" label="*Epost" rules={[required()]} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <BymTextInput controlId="avtaleData.kontaktpersonBymAdresse" label="*Adresse" rules={[required()]} />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <BymNumberInput
                    controlId="avtaleData.kontaktpersonBymTelefon"
                    label="*Telefon"
                    rules={[required()]}
                  />
                </Col>
              </Row>
            </VerticalTabContent>
            <VerticalTabContent disabled={!templateIsSelected || showDownloadButton} title="Velg leieobjekt">
              <div className="leieobjekt-picker">
                {leieObjekter &&
                  leieObjekter.map((leieObjekt, index) => {
                    return (
                      <BymFormValueWrapper
                        controlId={`kontrakt.leieObjekter[${index}].checked`}
                        mappedPropName="data-checked"
                        key={leieObjekt.id}
                      >
                        <div className="leieobjekt-picker-item">
                          <div className="leieobjekt-picker-icon">
                            <LeieObjektIcon />
                          </div>
                          <div className="leieobjekt-picker-content" key={leieObjekt.id}>
                            <EditIcon
                              onClick={() => {
                                setEditLeieObjekt(leieObjekt);
                                setShowAddLeieObjektDialog(true);
                              }}
                              style={{ float: "right", height: "22px", marginTop: "18px", cursor: "pointer" }}
                            />
                            <BymCheckboxInput
                              controlId={`kontrakt.leieObjekter[${index}].checked`}
                              label={leieObjekt.navn}
                              inline
                            />
                            <div>
                              <strong className={leieObjekt.adresse2 ? "" : "has-leieobjekt-error"}>Adresse:</strong>{" "}
                              <span>{leieObjekt.adresse2}</span>
                            </div>
                            <div>
                              <strong className={leieObjekt.bruksAreal ? "" : "has-leieobjekt-error"}>
                                Bruksareal (kvm):
                              </strong>{" "}
                              <span>{leieObjekt.bruksAreal}</span>
                            </div>
                            <div>
                              <strong className={leieObjekt.gnr ? "" : "has-leieobjekt-error"}>G.nr:</strong>{" "}
                              <span>{leieObjekt.gnr}</span>
                            </div>
                            <div>
                              <strong className={leieObjekt.bnr ? "" : "has-leieobjekt-error"}>B.nr:</strong>{" "}
                              <span>{leieObjekt.bnr}</span>
                            </div>
                            {uiState.showBygningsnummer && (
                              <div>
                                <strong className={leieObjekt.bygningsnummer ? "" : "has-leieobjekt-error"}>
                                  Bygning id:
                                </strong>{" "}
                                <span>{leieObjekt.bygningsnummer}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </BymFormValueWrapper>
                    );
                  })}
              </div>
              <div style={{ float: "left" }}>
                <BymCustomValidator<AvtaleData> validateFunction={leieObjektValidator} />
              </div>
              <div style={{ textAlign: "right" }}>
                <BymButton className="" controlId="nyttLeieobjektButton" bsStyle="bym-standard">
                  <span className="glyphicon glyphicon-plus" /> Nytt leieobjekt
                </BymButton>
              </div>
              {uiState.showKommune && (
                <div>
                  <BymTextInput
                    controlId="avtaleData.kommune"
                    label="Leieobjekt kommune"
                    placeholder=""
                    rules={[required()]}
                  />
                </div>
              )}
              {!uiState.hideBrukAvLeieobjekt && (
                <div className="leieobjekt-bruk">
                  <h4>*Bruk av leieobjekt</h4>
                  <p>Skriv teksten som kommer med i kontrakten om bruk av arealet.</p>
                  <div className="leieobjekt-bruk-text">
                    <p>
                      {getPrefiksArealetMaaBareBrukesTil(initialAvtaleData.avtaleData.kontraktsMal)} ...{" "}
                      <PilHoyreIcon />
                    </p>
                    <BymTextInput
                      componentClass="textarea"
                      controlId="avtaleData.leieobjektBruk"
                      label="Bruk av leieobjekt"
                      placeholder="fortsett setningen foran. Det du skriver her vil erstatte '...' i selve dokumentet"
                      hidelabel
                      rows={4}
                      rules={[required(), length(6, 500)]}
                    />
                  </div>
                </div>
              )}
              {uiState.showBygningBruk && (
                <div className="bygning-bruk">
                  <h4>*Bruk av bygningen(e)</h4>
                  <p>Skriv teksten som kommer med i kontrakten om bruk av bygningen(e).</p>
                  <div className="leieobjekt-bruk-text">
                    <p>
                      Bygningen(e) må bare benyttes til ... <PilHoyreIcon />
                    </p>
                    <BymTextInput
                      componentClass="textarea"
                      controlId="avtaleData.bygningBruk"
                      label="Bruk av bygningen(e)"
                      placeholder="fortsett setningen foran. Det du skriver her vil erstatte '...' i selve dokumentet"
                      hidelabel
                      rows={4}
                      rules={[required(), length(6, 500)]}
                    />
                  </div>
                </div>
              )}
              {uiState.showBoplikt && (
                <Row>
                  <Col md={12}>
                    <h4>*Boplikt for leieforholdets husrom</h4>
                    <BymRadioButtonsInput
                      inline
                      hidelabel
                      label="boplikt"
                      items={[
                        { id: "0", name: "Har ikke boplikt" },
                        { id: "1", name: "Har boplikt" }
                      ]}
                      controlId="avtaleData.boplikt"
                      rules={[required()]}
                    />
                    <br />
                  </Col>
                </Row>
              )}
              {uiState.showHusordensregler && (
                <div className="bygning-bruk">
                  <h4>*Husordensregler</h4>
                  <p>Skriv teksten som kommer med i kontrakten om husordensregler.</p>
                  <div>
                    <BymTextInput
                      componentClass="textarea"
                      controlId="avtaleData.husordensregler"
                      label="Husordensregler"
                      placeholder=""
                      hidelabel
                      rows={4}
                      rules={[required(), length(6, 1000)]}
                    />
                  </div>
                </div>
              )}
            </VerticalTabContent>
            <VerticalTabContent
              disabled={!templateIsSelected || showDownloadButton}
              title="Registrer fakturainformasjon"
            >
              <BymFormValueWrapper<AvtaleData>
                mapFunction={formValues => ({
                  currentValues: formValues
                })}
              >
                <FakturaInformasjon uiState={uiState} leieObjekter={leieObjekter} />
              </BymFormValueWrapper>
            </VerticalTabContent>
            <VerticalTabContent disabled={!templateIsSelected || showDownloadButton} title="Opprett leieforhold">
              {!uiState.hideFremleie && (
                <Row>
                  <Col md={8}>
                    <h4>Fremleie/fremlån</h4>
                    <small>
                      Som utgangspunkt tillater vi ikke at leietaker fremleier eller fremlåner eiendommen (alternativ
                      1). I enkelte tilfeller er det likevel nødvendig å tillate fremleie eller fremlån fordi det er
                      nødvendig ut i fra leieavtalens leieformål. Eksempelvis utleie til organisatør av julemarked som
                      leier bodene videre til forskjellige aktører (alternativ 2).
                    </small>
                    <BymRadioButtonsInput
                      hidelabel
                      controlId="avtaleData.fremLeieOption"
                      items={fremLeieOptions}
                      rules={[required()]}
                    />
                  </Col>
                </Row>
              )}
              {!uiState.hideTvistelosning && (
                <Row>
                  <Col md={12}>
                    <div className="leieobjekt-bruk" style={{ padding: "15px 60px 15px 15px" }}>
                      <Row>
                        <Col md={8}>
                          <h4>Tvisteløsning</h4>
                          <small>
                            Forskjellen mellom alternativ 1 og 2 er hvorvidt leietaker er en enhet utenfor eller i
                            organisasjonen Oslo kommune (OK) eller utenfor organisasjonen. Private selskaper er utenfor
                            organisasjonen OK, uavhengig om OK eier en stor andel av selskapet. VAV og EBY er eksempel
                            på enheter som er en del av organisasjonen OK. <br />
                            For enheter som ikke er en del av OK velger du alternativ 1, dette skal gå for tingretten
                            ved eventuell tvisteløsning. For enheter som er en del av OK velger du alternativ 2. <br />
                            Hvis du er usikker kontakter du juridiske avdeling.
                          </small>
                          <BymRadioButtonsInput
                            hidelabel
                            controlId="avtaleData.tvistOption"
                            items={tvistOptions}
                            rules={[required()]}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={8}>
                  <h4>Særlige bestemmelser</h4>
                  <small>
                    Her kan du legge inn særlige forhold som gjelder det konkrete leieobjektet eller leieforholdet. Slik
                    som vernede trær, bruk av høyttaleranlegg, tilleggsforpliktelser m.fl. Dersom du skal gjøre unntak
                    fra bestemmelsene i standardmalen, må dette gjøres i en særavtale. Husk da å sende særavtalen på
                    gjennomgang til juridisk avdeling.
                  </small>
                  <BymTextInput
                    componentClass="textarea"
                    controlId="avtaleData.saervilkaar"
                    placeholder="Begynn å skrive her ..."
                    hidelabel
                    rows={3}
                    // rules={[required(), length(6, 255)]}
                  />
                </Col>
              </Row>
              {uiState.showOrdensregler && (
                <Row>
                  <Col md={8}>
                    <h4>Husordensregler</h4>
                    <BymTextInput
                      componentClass="textarea"
                      controlId="avtaleData.ordensregler"
                      placeholder="Begynn å skrive her ..."
                      hidelabel
                      rows={3}
                      // rules={[required(), length(6, 255)]}
                    />
                  </Col>
                </Row>
              )}
              {uiState.showAntallNokler && (
                <Row>
                  <Col md={3}>
                    <BymNumberInput
                      controlId="avtaleData.antallNokler"
                      label="Antall utleverte nøkler"
                      rules={[required()]}
                    />
                  </Col>
                </Row>
              )}
            </VerticalTabContent>
            <VerticalTabContent disabled={!templateIsSelected || showDownloadButton} title="Vedlegg">
              <p>
                <small>
                  Husk å legge til alle vedlegg, hvis ikke blir disse dokumentene ikke en del av kontrakten.
                </small>
              </p>
              <BymFormValueWrapper controlId="avtaleData.vedlegg" mappedPropName="items">
                <AttachmentList />
              </BymFormValueWrapper>
            </VerticalTabContent>
            <VerticalTabContent disabled={!templateIsSelected} showNextButton={false} title="Last ned kontrakt - PDF">
              <BymFormValueWrapper<AvtaleData> mapFunction={formValues => ({ currentValues: formValues })}>
                <OpprettKontraktTab
                  isEditMode={isEditMode}
                  showAddKontraktSpinner={showAddKontraktSpinner}
                  showDownloadButton={showDownloadButton}
                  uiState={uiState}
                />
              </BymFormValueWrapper>
            </VerticalTabContent>
          </VerticalTabs>
        </ModalBody>
      </BymForm>
    </Modal>
  );
};

export default AddKontraktAvtaleDialog;
