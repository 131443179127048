import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { orderBy } from "lodash-es";
import { HeaderCell, Table } from "../Common/Tables";
import * as dateUtils from "../../utils/dateUtils";
import ConfirmDialog from "../Common/ConfirmDialog";
import axios from "../../services/axios";
import LoggDetailsDialog from "./LoggDetailsDialog";
import getFormattedLog from "utils/logUtils";
import "./LoggSection.css";

function LoggSection({
  className = undefined,
  onUndo,
  afterUndo = undefined,
  logg = [],
  kostSteder,
  kontoNummere,
  kontraktTyper,
  prisTyper,
  kontraktStatuser,
  faktureringTyper,
  faktureringTidspunkter,
  betalingTerminTyper
}) {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [loggDetails, setLoggDetails] = useState(null);
  const [undoItem, setUndoItem] = useState(null);
  const [sort, setSort] = useState({
    cellKey: "opprettetDato",
    orders: "desc"
  });

  const readableKontraksLogg = getFormattedLog(
    logg,
    kostSteder,
    kontoNummere,
    kontraktTyper,
    prisTyper,
    kontraktStatuser,
    faktureringTyper,
    faktureringTidspunkter,
    betalingTerminTyper
  );

  const sortedLogg = orderBy(readableKontraksLogg, [sort.cellKey], [sort.orders]);

  const showUndoConfirmDialog = loggItem => {
    // this.setState({ showConfirmDialog: true, undoItem: loggItem });
    setShowConfirmDialog(true);
    setUndoItem(loggItem);
  };

  const showLoggDetails = loggDetailsArg => {
    // this.setState({ loggDetails: JSON.parse(loggDetails) });
    setLoggDetails(JSON.parse(loggDetailsArg));
  };

  const undo = async () => {
    await axios.put(undoItem.linker.angre.href).then(() => {
      if (afterUndo) afterUndo();
    });
    // this.setState({ showConfirmDialog: false, undoItem: null });
    setShowConfirmDialog(false);
    setUndoItem(null);
    if (onUndo) onUndo(undoItem);
  };

  const onSort = sortValues => {
    setSort(sortValues);
  };

  const renderRow = loggItem => (
    <tr key={loggItem.id}>
      <td style={{ width: "180px" }}>{dateUtils.getFormatedDateTime(loggItem.opprettetDato)}</td>
      <td>{loggItem.opprettetAvNavn}</td>
      <td>{loggItem.tekst}</td>
      <td className="logg-details">
        {loggItem.linker?.angre ? (
          <Button onClick={() => showUndoConfirmDialog(loggItem)} bsStyle="link">
            Angre
          </Button>
        ) : (
          ""
        )}
        {"   "}
        {loggItem && loggItem.endringer ? (
          <Button onClick={() => showLoggDetails(loggItem.endringer)} bsStyle="link">
            Detaljer
          </Button>
        ) : (
          <span>Detaljer ikke tilgjengelig</span>
        )}
      </td>
    </tr>
  );

  return (
    <div className={`logg-component ${className}` || ""}>
      {showConfirmDialog && (
        <ConfirmDialog
          show
          onConfirm={undo}
          onClose={() => setShowConfirmDialog(false)}
          message="Er du sikker på at du vil angre sletting?"
          title="Angre"
        />
      )}
      {(!logg || logg.length < 1) && <i className="logg-empty">Det er ingen logg for denne ressursen enda</i>}
      {loggDetails && <LoggDetailsDialog loggDetails={loggDetails} onHide={() => setLoggDetails(null)} />}
      {logg && logg.length > 0 && (
        <Table id="logg-liste" onSort={onSort} sort={sort} items={sortedLogg} renderRow={renderRow}>
          <HeaderCell cellKey="opprettetDato">Dato</HeaderCell>
          <HeaderCell cellKey="opprettetAv">Utført av</HeaderCell>
          <HeaderCell cellKey="tekst">Hendelse</HeaderCell>
          <HeaderCell cellKey="undo" />
        </Table>
      )}
    </div>
  );
}

LoggSection.propTypes = {
  className: PropTypes.string,
  onUndo: PropTypes.func.isRequired,
  afterUndo: PropTypes.func,
  logg: PropTypes.arrayOf(PropTypes.object),
  kostSteder: PropTypes.arrayOf(PropTypes.object).isRequired,
  kontoNummere: PropTypes.arrayOf(PropTypes.object).isRequired,
  kontraktTyper: PropTypes.arrayOf(PropTypes.object).isRequired,
  prisTyper: PropTypes.arrayOf(PropTypes.object).isRequired,
  kontraktStatuser: PropTypes.arrayOf(PropTypes.object).isRequired,
  faktureringTyper: PropTypes.arrayOf(PropTypes.object).isRequired,
  betalingTerminTyper: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default LoggSection;
